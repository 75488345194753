var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "form form--narrow" }, [
    _c("h2", { staticClass: "form__heading" }, [_vm._v("Register")]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { action: "#" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.signUp()
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "form__group" },
          [
            _c("Field", {
              ref: "email",
              attrs: {
                type: "email",
                name: "email",
                label: "E-mail",
                validation: _vm.emailValidation,
              },
              model: {
                value: _vm.signUpData.email,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.signUpData,
                    "email",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "signUpData.email",
              },
            }),
            _vm._v(" "),
            _c("Password", {
              ref: "password",
              attrs: {
                name: "password",
                label: "Password",
                validation: _vm.passwordValidation,
                toggled: _vm._toggled,
              },
              on: {
                "update:toggled": function ($event) {
                  _vm._toggled = $event
                },
              },
              model: {
                value: _vm.signUpData.password,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.signUpData,
                    "password",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "signUpData.password",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.errors.length
          ? _c(
              "div",
              { staticClass: "form__group form__group--message" },
              _vm._l(_vm.errors, function (error, i) {
                return _c(
                  "p",
                  { key: i, staticClass: "form__message form__message--error" },
                  [
                    _c("span", {
                      staticClass: "form__message-icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.error) },
                    }),
                    _vm._v("\n        " + _vm._s(error) + "\n      "),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form__button-group form__button-group--right" },
          [
            _c("fs-button-main", { attrs: { loading: _vm.loading } }, [
              _vm._v("Register"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "form__group form__group--center form__button-group--full-width-with-margin",
          },
          [
            _c(
              "fs-button-main",
              {
                attrs: {
                  styling: "outlined",
                  size: "large",
                  long: true,
                  event: _vm.mode == "popup" ? "" : "click",
                  to: _vm.loginLocation,
                },
                nativeOn: {
                  click: function ($event) {
                    return (function () {
                      return _vm.mode == "popup"
                        ? _vm.$parent.open("logIn")
                        : ""
                    })($event)
                  },
                },
              },
              [_vm._v("\n        Login\n      ")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "form__group form__group--new-section form__group--center",
      },
      [
        _c("strong", { staticClass: "form__subtitle" }, [
          _vm._v("Already have an account?"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }